import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment' ;
import {get} from 'lodash' ;
import '../css/style.css';
import '../css/font-awesome-pro.css';
import pagination from '../images/pagination.png';
import { useEffect, useState } from "react";
import { energyChart, keycutChart, getKeyCutData } from "../api";
import { Line } from 'react-chartjs-2';
import DataTable from 'datatables.net-dt';

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        }
    },
};


function DeviceDetails2() {
    const navigate = useNavigate();
    const [keycutData, setKeycutData] = useState([]);
    const [ecData, setECData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Energy',
                data: [],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    });
    const [kcData, setKCData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Total Key Cut',
                data: [],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Flat Key Cut',
                data: [],
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
                label: 'Single Side Cut',
                data: [],
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
            },
            {
                label: 'Double Side Cut',
                data: [],
                borderColor: 'rgb(255, 159, 63)',
                backgroundColor: 'rgba(255, 159, 63, 0.5)',
            },
            {
                label: 'Bit Cut',
                data: [],
                borderColor: 'rgb(153, 102, 255)',
                backgroundColor: 'rgba(153, 102, 255, 0.5)',
            },
        ],
    });

    const { serialNumber="x" } = useParams();

    useEffect(()=>{
        let token = sessionStorage.getItem('token');
        if (!token) {
          navigate('/', {replace: true});
        }
        const admin = sessionStorage.getItem('isAdmin');
        if(admin && admin == 'true' && !serialNumber) {
            navigate('list', {replace: true});
        }
        fetchEnergyChart();
        fetchKeycutChart();
        getKeyCutData(serialNumber).then((resp)=> {
            setKeycutData(resp);
        })
    }, [])

    useEffect(()=> {
        if (keycutData.length) {
            new DataTable('#KeyCutTable')
        }
    },[keycutData]);

    const fetchEnergyChart = (e)=> {
        energyChart(serialNumber, get(e, 'target.value', '7days')).then((resp)=> {
            setECData({
                labels: resp.labels,
                datasets: [
                    {
                        label: 'Energy',
                        data: resp.data,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                ],
            });
        })
    }
    
    const fetchKeycutChart = (e)=> {
        keycutChart(serialNumber, get(e, 'target.value', '7days')).then((resp)=> {
            setKCData({
                labels: resp.labels,
                datasets: [
                    {
                        label: 'Total Key Cut',
                        data: resp.data.map((d)=>d.keycount),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: 'Flat Key Cut',
                        data: resp.data.map((d)=>d.flatcut),
                        borderColor: 'rgb(54, 162, 235)',
                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                    },
                    {
                        label: 'Single Side Cut',
                        data: resp.data.map((d)=>d.singleside),
                        borderColor: 'rgb(75, 192, 192)',
                        backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    },
                    {
                        label: 'Double Side Cut',
                        data: resp.data.map((d)=>d.doubleside),
                        borderColor: 'rgb(255, 159, 63)',
                        backgroundColor: 'rgba(255, 159, 63, 0.5)',
                    },
                    {
                        label: 'Bit Cut',
                        data: resp.data.map((d)=>d.bitcut),
                        borderColor: 'rgb(153, 102, 255)',
                        backgroundColor: 'rgba(153, 102, 255, 0.5)',
                    },
                ],
            });
        })
    }
    

    return (
        <div class="page-content">
            <section class="">
                <div class="grid-1">

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 card">
                        <div class="card-header">
                            <h1 class="card-heading">Key Count Line Chart</h1>
                            <div class="group-btn">

                                <div class="input-group">
                                    <label>Sort By</label>
                                    <select onChange={fetchKeycutChart}>
                                        <option value="7days">Last 7 Days</option>
                                        <option value="30days">Last 30 Days</option>
                                        <option value="6month">6 Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <Line
                                options={options}
                                data={kcData}
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 card">
                        <div class="card-header">
                            <h1 class="card-heading">Energy Unit Line Chart</h1>
                            <div class="group-btn">

                                <div class="input-group">
                                    <label>Sort By</label>
                                    <select onChange={fetchEnergyChart}>
                                        <option value="7days">Last 7 Days</option>
                                        <option value="30days">Last 30 Days</option>
                                        <option value="6month">6 Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <Line
                                options={options}
                                data={ecData}
                            />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 card">

                        <div class="card-header">
                            <h1 class="card-heading">Device Data</h1>
                            <div class="group-btn">

                            </div>
                        </div>

                        <div class="card-body">
                            {/* <div class="my-filter">
                                <div class="group-btn">
                                    <div class="input-group">
                                        <label >Sort By</label>
                                        <select>
                                            <option value="7">Last 7 Days</option>
                                            <option value="30">Last 30 Days</option>
                                            <option value="90">Last 90 Days</option>
                                            <option value="180">Last 180 Days</option>
                                            <option value="365">Yearly</option></select>
                                    </div>
                                    <a class="btn btn-primary download-btn font-12">Download</a>
                                </div>

                                <div class="group-btn">
                                    <div class="search">
                                        <i class="liv-search w-18"></i>
                                        <input type="search" placeholder="Search"/>
                                    </div>
                                </div>
                            </div> */}
                            <div class="my-table">
                                <table id="KeyCutTable" class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Key Count</th>
                                            <th scope="col">Flat Cut</th>
                                            <th scope="col">Bit cut</th>
                                            <th scope="col">Single Side</th>
                                            <th scope="col">Double Side</th>
                                            <th scope="col">Unit</th>
                                            <th scope="col">Motor Run</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {keycutData.map((d,i) => (
                                            <tr>
                                                <td>{i+1}</td>
                                                <td>{moment(d.date).format('DD MMM YY')}</td>
                                                <td>{d.v.keycount}</td>
                                                <td>{d.v.flatcut}</td>
                                                <td>{d.v.bitcut}</td>
                                                <td>{d.v.singleside}</td>
                                                <td>{d.v.doubleside}</td>
                                                <td>{parseInt(d.v.energy)/1000}</td>
                                                <td>{d.v.runtime}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </div>
    );
}

export default DeviceDetails2;
