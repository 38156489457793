import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import '../css/style.css';
import logo from '../images/minda-logo.png';
import { getDealers, register } from '../api';
function Reg2() {
    const navigate = useNavigate();
    const { serialNumber } = useParams();
    const [dealers, setDealers] = useState([]);
    const [formData, setFormData] = useState({
        dealer: null,
        fName: '',
        lName: '',
        mobile: '',
        email: '',
        pwd: '',
        cPwd: '',
        addr: '',
        city: '',
        state: '',
        pincode: '',
        machSerial: serialNumber
    });
    
    const [formErr, setFormErr] = useState({
        dealer: 'Required',
        fName: 'Required',
        lName: 'Required',
        mobile: 'Required',
        email: '',
        pwd: 'Required',
        cPwd: 'Required',
        addr: 'Required',
        city: 'Required',
        state: 'Required',
        pincode: 'Required'
    });

    useEffect(() => {
        console.log(serialNumber);
        getDealers().then(res => {
            if (res) {
                setDealers(res);
            }
        })
    },[])

    const updateForm = (key, value, required) => {
        let fd = {...formData};
        if (required && !value) {
            formErr[key] = 'Required';
            setFormErr(formErr);
            return;
        }
        fd[key] = value;
        setFormData(fd);
        formErr[key] = '';
        setFormErr(formErr);
    }

    const submit = () => {
        for (let k of Object.keys(formErr)) {
            if (k && formErr[k]) {
                alert(`${k} is invalid`);
                return;
            }
        }
        if (formData.pwd.length < 8) {
            alert('Password must be atleast 8 character long');
            return
        }
        if (formData.pwd != formData.cPwd) {
            alert(`Password confirmation doesn't match`);
            return
        }
        if (!formData.pwd.match(/[A-Z]/g)) {
            alert(`Password must contain Capital letter`);
            return
        }
        if (!formData.pwd.match(/[a-z]/g)) {
            alert(`Password must contain small letter`);
            return
        }
        if (!formData.pwd.match(/[0-9]/g)) {
            alert(`Password must contain number letter`);
            return
        }
        register(formData).then((resp)=> {
            if(resp && resp.success) {
                if (resp.token) {
                    sessionStorage.setItem('token', resp.token);
                    navigate('/home',{replace: true});
                }
            }
        })
    }

    const states = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Maharashtra",
        "Madhya Pradesh",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Tripura",
        "Telangana",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
        "Andaman & Nicobar",
        "Chandigarh",
        "Dadra & Nagar Haveli",
        "Daman & Diu",
        "Jammu & Kashmir(UT)",
        "Lakshadweep",
        "Ladakh",
        "Puducherry",
    ]

    return (
        <div class="main-page">

            <div class="login-main">

                <div class="logo">
                    <img src={logo} />
                </div>

                <div class="login-wrapper">

                    <div class="login-form">

                        <div class="form">
                            <div class="form-group">
                                <select onChange={(x)=>{updateForm('dealer', x.target.value)}} tabindex="0">
                                    <option>Select Dealer</option>
                                    {dealers.map((d)=>
                                        (<option value={d._id} key={d._id}>{d.name}</option>))}
                                </select>
                            </div>
                            <div class="form-group" onChange={(x)=>{updateForm('fName', x.target.value)}}>
                                <input type="text" placeholder="First Name *" tabindex="1" />
                            </div>
                            <span style={{fontSize: 'smaller', color:'red', margin: 0}}>{formErr['fName']}</span>
                            <div class="form-group" onChange={(x)=>{updateForm('lName', x.target.value, true)}}>
                                <input type="text" placeholder="Last Name *" tabindex="2" />
                            </div>
                            <span style={{fontSize: 'smaller', color:'red', margin: 0}}>{formErr['lName']}</span>
                            <div class="form-group" onChange={(x)=>{updateForm('mobile', x.target.value, true)}}>
                                <input type="text" placeholder="Mobile *" tabindex="3" />
                            </div>
                            <span style={{fontSize: 'smaller', color:'red', margin: 0}}>{formErr['mobile']}</span>
                            <div class="form-group" onChange={(x)=>{updateForm('email', x.target.value)}}>
                                <input type="text" placeholder="Email" tabindex="4" />
                            </div>
                            <span style={{fontSize: 'smaller', color:'red', margin: 0}}>{formErr['email']}</span>
                            <div class="form-group" onChange={(x)=>{updateForm('pwd', x.target.value, true)}}>
                                <input type="password" placeholder="Password *" tabindex="5" />
                            </div>
                            <span style={{fontSize: 'smaller', color:'red', margin: 0}}>{formErr['pwd']}</span>
                            <div class="form-group" onChange={(x)=>{updateForm('cPwd', x.target.value, true)}}>
                                <input type="password" placeholder="Confirm Password *" tabindex="6" />
                            </div>
                            <span style={{fontSize: 'smaller', color:'red', margin: 0}}>{formErr['cPwd']}</span>
                            <div class="form-group" onChange={(x)=>{updateForm('addr', x.target.value, true)}}>
                                <input type="text" placeholder="Address *" tabindex="7" />
                            </div>
                            <span style={{fontSize: 'smaller', color:'red', margin: 0}}>{formErr['addr']}</span>
                            <div class="form-group" onChange={(x)=>{updateForm('city', x.target.value, true)}}>
                                <input type="text" placeholder="City *" tabindex="8" />
                            </div>
                            <span style={{fontSize: 'smaller', color:'red', margin: 0}}>{formErr['city']}</span>
                            <div class="form-group" onChange={(x) => { updateForm('state', x.target.value, true)}}>
                                <select>
                                    <option value='' disabled>Select State</option>
                                    {
                                        states.map(s=>(
                                            <option value={s}>{s}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <span style={{fontSize: 'smaller', color:'red', margin: 0}}>{formErr['state']}</span>
                            <div class="form-group" onChange={(x)=>{updateForm('pincode', x.target.value, true)}}>
                                <input type="text" placeholder="Pincode *" tabindex="10" />
                            </div>
                            <span style={{fontSize: 'smaller', color:'red', margin: 0}}>{formErr['pincode']}</span>
                            {/* <div class="form-group mt-20">
                                <button type="submit" class="btn btn-primary btn-block" tabindex="0">Allow Geolocation</button>
                            </div> */}
                            <div class="form-group mt-10">
                                <button type="submit" class="btn btn-primary btn-block" tabindex="11" onClick={submit}>
                                    Sign Up</button>
                            </div>
                        </div>


                    </div>

                </div>

            </div>



        </div>
    );
}

export default Reg2;
