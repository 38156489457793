import { useState } from "react";
import { useNavigate } from "react-router-dom";

import '../css/style.css';
import logo from '../images/minda-logo.png';
import { isRegistered } from '../api';

function Registration() {
    const navigate = useNavigate();
    const [serial, setSerial] = useState('');

    const goNext = () => {
        if (!serial) {
            alert('Provide Serial Number')
            return;
        }
        isRegistered(serial).then((res)=>{
            if (res && res.success) {
                navigate(`${serial}`);
            }
        })
    }

    return (
        <div class="main-page">

            <div class="login-main">

                <div class="logo">
                    <img src={logo} />
                </div>

                <div class="login-wrapper">

                    <div class="login-form">

                        <div class="form">
                            {/* <div class="form-group">
                                <input type="text" placeholder="Mobile No" tabindex="0" />
                            </div> */}
                            <div class="form-group" onChange={(e)=>setSerial(e.target.value)}>
                                <input type="text" placeholder="Serial Key" tabindex="0" required/>
                            </div>

                            <div class="form-group mt-20">
                                <button 
                                    type="submit" 
                                    class="btn btn-primary btn-block" 
                                    onClick={goNext}
                                    tabindex="0">
                                        Next</button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    );
}

export default Registration;
