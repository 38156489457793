import { useNavigate, Outlet} from "react-router-dom";
import {get} from 'lodash' ;
import '../css/style.css';
import '../css/font-awesome-pro.css';
import logo from '../images/minda-logo.png';
import { useEffect, useState } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { changePassword } from "../api";

const modaLStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    bgcolor: 'background.paper',
    borderRadius: '3px',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 5,
    '& .MuiTextField-root': { m: 1, width: '25ch' },
  };


function Home() {
    const navigate = useNavigate();
    const [showChangePwd, setChangePwd] = useState(false);
    const [formData, setFormData] = useState({
        pwd: '',
        cPwd: ''
    });
    
    const [formErr, setFormErr] = useState({
        pwd: 'Required',
        cPwd: 'Required'
    });

    const updateForm = (key, value, required) => {
        let fd = {...formData};
        if (required && !value) {
            formErr[key] = 'Required';
            setFormErr(formErr);
            return;
        }
        fd[key] = value;
        setFormData(fd);
        formErr[key] = '';
        setFormErr(formErr);
    }

    const submitChangePassword = () => {
        for (let k of Object.keys(formErr)) {
            if (k && formErr[k]) {
                alert(`${k} is invalid`);
                return;
            }
        }
        if (formData.pwd.length < 8) {
            alert('Password must be atleast 8 character long');
            return
        }
        if (formData.pwd != formData.cPwd) {
            alert(`Password confirmation doesn't match`);
            return
        }
        if (!formData.pwd.match(/[A-Z]/g)) {
            alert(`Password must contain Capital letter`);
            return
        }
        if (!formData.pwd.match(/[a-z]/g)) {
            alert(`Password must contain small letter`);
            return
        }
        if (!formData.pwd.match(/[0-9]/g)) {
            alert(`Password must contain number letter`);
            return
        }
        changePassword(formData).then((resp)=> {
            if(resp) {
                if (resp.success) {
                    alert(resp.message);
                }else {
                    if (resp.message) {
                        alert(resp.message);
                        setChangePwd(false);
                    } else {
                        alert('Something went wrong!');
                    }
                }
            } else {
                alert('Something went wrong!');
            }
        })
    }

    return (
        <div class="page-wrapper">
        <div class="container-fuild">
            <div class="header">
                <div class="header-container">
                    <div class="logo">
                    <img src={logo} />
                    {/* <img src={logo} /> <font>Smart Karkhana</font> */}
                    </div>

                    <div class="user">
                            <a onClick={()=>{setChangePwd(true)}} style={{marginRight: '5px'}}>
                                Change Password
                            </a>
                            &nbsp;|&nbsp;
                            <a onClick={()=>
                                {sessionStorage.clear();
                                navigate('/', {replace: true});}}
                                title="Log Out">
                                <i class="fa-solid fa-right-from-bracket"></i>        
                            </a>
                        {/* <div class="circle">
                            {/* <img src={userIcon} class="header-profile-user" alt="" onClick=/> 
                        </div> */}

                        
                    </div>
                </div>
            </div>

            <div class="row mobile-view">
                <div class="left-panel-main d-flex"> {/*Use Left-nav-icon class for icon menu*/}

                    <div class="left-nav">
                        <ul>
                            <li><a href="#"><i class="liv-home w-24"></i> <span>Home</span></a></li>
                            {/* <li><a href="#"><i class="liv-devices w-24"></i> <span>All Devices</span></a></li> */}

                            <li class="mobile-display"><a href="#"><i class="liv-more w-24"></i> <span>More</span></a>
                            </li>
                        </ul>
                    </div>

                    <div class="fold-btn">
                        <div class="icon">
                            <i class="liv-fold w-14"></i>
                        </div>
                    </div>

                </div>

                <div class="main page-inner overflow-hidden">
                    <div class="breadcrumb-container">
                        <ul class="breadcrumb">
                            <li><a href="#">IoT Platform</a></li>
                            <li><a href="#" class="active">Dashboard</a></li>
                        </ul>
                    </div>
                    <Outlet/>
                </div>
            </div>

        </div>
        <Modal
                open={showChangePwd}
                onClose={() => setChangePwd(false)}
                aria-labelledby="Change Password"
                aria-describedby="Change User's Password"
            >
                <Box
                    component="form"
                    sx={modaLStyle}
                    autoComplete="off"
                >
                    <h2>
                        Change Password
                        <span style={{float: 'right', cursor: 'pointer'}} onClick={() => setChangePwd(false)}>✕</span>
                    </h2>
                    <br/>
                    <br/>
                    <div class="form-group" onChange={(x) => { updateForm('pwd', x.target.value, true) }}>
                        <input type="password" placeholder="Password *" tabindex="5" />
                    </div>
                    <span style={{ fontSize: 'smaller', color: 'red', margin: 0 }}>{formErr['pwd']}</span>
                    <br/>
                    <div class="form-group" onChange={(x) => { updateForm('cPwd', x.target.value, true) }}>
                        <input type="password" placeholder="Confirm Password *" tabindex="6" />
                    </div>
                    <span style={{ fontSize: 'smaller', color: 'red', margin: 0 }}>{formErr['cPwd']}</span>
                    <br/>
                    <button type="submit" class="btn btn-primary btn-block" tabindex="11" onClick={submitChangePassword}>
                    Change Password</button>
                </Box>
            </Modal>
    </div>
    );
}

export default Home;
