const baseUrl = '';
const responseHandle = (res) => {
    if (res.status == 200) {
        return res.json();
    } else if (res.status == 500 || res.status == 400) {
        res.json().then((e)=>{
            if (e && e.message) {
                alert(e.message)
            }
            console.error(e);
        });
    } else if (res.status ==403) {
        sessionStorage.clear();
        window.location.reload(false);
    }
}

const getDealers = () => {
    return fetch(baseUrl+'/dealer/', {
        method: 'get'
    }).then(responseHandle);
}

const isRegistered = (machSerial) => {
    return fetch(baseUrl+'/isRegistered/'+machSerial, {
        method: 'get'
    }).then(responseHandle);
}

const register = (body) => {
    return fetch(baseUrl+'/register', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(responseHandle);
}

const login = (userId, pwd) => {
    return fetch(baseUrl+'/login', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({userId, pwd})
    }).then(responseHandle);
}

const getData = () => {
    return fetch(baseUrl+'/device/', {
        method: 'get',
        headers: {
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(responseHandle);
}

const getAllDevices = () => {
    return fetch(baseUrl+'/device/all', {
        method: 'get',
        headers: {
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(responseHandle);
}

const getSerialData = (serialNumber) => {
    return fetch(baseUrl+'/device/'+serialNumber, {
        method: 'get',
        headers: {
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(responseHandle);
}

const switchDevice = (serialNumber, state) => {
    return fetch(baseUrl+'/device/switch/'+serialNumber, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify({state})
    }).then(responseHandle);
}

const addDevice = (data) => {
    return fetch(baseUrl+'/device/add', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(responseHandle);
}

const updateDevice = (data) => {
    return fetch(baseUrl+'/device/update', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(responseHandle);
}

const changePassword = (data) => {
    return fetch(baseUrl+'/device/change-password', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(responseHandle);
}

const energyChart = (machSerial, query) => {
    return fetch(baseUrl+`/device/energy-chart/${machSerial}?q=${query}`, {
        method: 'get',
        headers: {
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(responseHandle);
}

const keycutChart = (machSerial, query) => {
    return fetch(baseUrl+`/device/keycut-chart/${machSerial}?q=${query}`, {
        method: 'get',
        headers: {
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(responseHandle);
}

const getKeyCutData = (machSerial) => {
    return fetch(baseUrl+`/device/keycut-data/${machSerial}`, {
        method: 'get',
        headers: {
            'Authorization': sessionStorage.getItem('token')
        }
    }).then(responseHandle);
}

export {
    getDealers,
    isRegistered,
    register,
    login,
    getData,
    getAllDevices,
    getSerialData,
    switchDevice,
    addDevice,
    updateDevice,
    changePassword,
    energyChart,
    keycutChart,
    getKeyCutData,
}