import { useNavigate } from "react-router-dom";

import '../css/style.css';
import logo from '../images/minda-logo.png';
import { useState, useEffect } from "react";
import { login } from "../api";

function Login() {
    const navigate = useNavigate();

    const [userId, setUserId] = useState('');
    const [pwd, setPwd] = useState('');

    useEffect(()=>{
        let token = sessionStorage.getItem('token');
        if (token) {
          navigate('../home', {replace: true});
        }
    },[]);
    
    const submitLogin = () => {
        if (!userId) {
            alert ('Enter Mobile as userId');
            return;
        }
        if (!pwd) {
            alert ('Password is required');
            return;
        }
        login(userId, pwd).then(resp=> {
            if(resp && resp.success) {
                if (resp.token) {
                    sessionStorage.setItem('token', resp.token);
                    sessionStorage.setItem('isAdmin', resp.isAdmin);
                    sessionStorage.setItem('isDealer', resp.isDealer);
                    setTimeout(()=>{
                        navigate('/home',{replace: true});
                    },1)
                }
            }
        })
    }
    return (
        <div class="main-page">
            <div class="login-main">

                <div class="logo">
                    <img src={logo} />
                </div>

                <div class="login-wrapper">

                    <div class="login-form">
                        <diV class="title text-center">
                            <h1>Log Into Your Account</h1>
                            <p class="text-slate-500 dark:text-zink-200">Sign in to continue.</p>
                        </diV>
                        <div class="form">
                            <div class="form-group username">
                                <input type="text" placeholder="Enter Mobile" tabindex="0" onChange={(e)=>setUserId(e.target.value)}/>
                            </div>
                            <div class="form-group password">
                                <input type="password" placeholder="Enter Password" tabindex="1" onChange={(e)=>setPwd(e.target.value)}/>
                            </div>
                            <div class="form-group mt-20">
                                <button type="submit" class="btn btn-primary btn-block" onClick={submitLogin} >Sign In</button>
                            </div>
                        </div>
                        <div class="sign-up text-center mt-20">
                            <a href="#/reg">New User?</a>
                            Register with us
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Login;
