import { useEffect } from 'react';
import Home from './home/home';
import Login from './login/login';
import Registration from './login/registration';
import Registration2 from './login/registration-2';
import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";
import DeviceData from './home/deviceData';
import DeviceDetails from './home/deviceDetails';
import DeviceList from './home/deviceList';
import DeviceDetails2 from './home/deviceDetails2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function App() {
  useEffect(() => {
    document.title = 'Minda IoT Platform';
  }, []);
  return (
    <HashRouter>
      <Routes>
        <Route path="/reg" element={<Registration />} />
        <Route path="/reg/:serialNumber" element={<Registration2 />} />
        <Route path="login" element={<Login />} />
        <Route path="home" element={<Home />} >
          <Route path="" element={<DeviceData />}/>
          <Route path="device/:serialNumber" element={<DeviceData />}/>
          <Route path="details" element={<DeviceDetails />}/>
          <Route path="details2" element={<DeviceDetails2 />}/>
          <Route path="details/:serialNumber" element={<DeviceDetails />}/>
          <Route path="details2/:serialNumber" element={<DeviceDetails2 />}/>
          <Route path="list" element={<DeviceList />}/>
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
