import { useNavigate, useParams } from "react-router-dom";
import {get} from 'lodash' ;
import Switch from '@mui/material/Switch';

import '../css/style.css';
import '../css/font-awesome-pro.css';
import userIcon from '../images/user.jpg';
import prediction1Icon from '../images/temp-01.png';
import prediction2Icon from '../images/temp-02.png';
import prediction3Icon from '../images/temp-03.png';
import prediction4Icon from '../images/temp-04.png';
import prediction5Icon from '../images/temp-05.png';
import gaugeChart from "../images/gauge-chart-2.png";
import { useEffect, useState } from "react";
import { getData, getSerialData, switchDevice } from "../api";

function DeviceData() {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const { serialNumber } = useParams();
    useEffect(()=>{
        let token = sessionStorage.getItem('token');
        if (!token) {
          navigate('/', {replace: true});
        }
        const admin = sessionStorage.getItem('isAdmin');
        if(admin && admin == 'true' && !serialNumber) {
            navigate('list', {replace: true});
        }
        if (serialNumber) {
            getSerialData(serialNumber).then((resp)=> {
                setData(resp);
            })
        } else {
            getData().then((resp)=> {
                setData(resp);
            })
        }
    }, [])

    const machineHealth = ()=> {
        let runtime = (get(data, 'data.runtime',0))/60;
        if (runtime < (get(data, 'motorHealth',500)/5)) {
            return (<div class="counter-value prediction">
                <img src={prediction1Icon} width="50px" alt="" /> Excellent
            </div>)
        }
        else if (runtime < 2*(get(data, 'motorHealth',500)/5)) {
            return (<div class="counter-value prediction">
                <img src={prediction2Icon} width="50px" alt="" /> Good
            </div>)
        }
        else if (runtime < 3*(get(data, 'motorHealth',500)/5)) {
            return (<div class="counter-value prediction">
                <img src={prediction3Icon} width="50px" alt="" /> Fair
            </div>)
        }
        else if (runtime < 4*(get(data, 'motorHealth',500)/5)) {
            return (<div class="counter-value prediction">
                <img src={prediction4Icon} width="50px" alt="" /> Poor
            </div>)
        }
        else {
            return (<div class="counter-value prediction">
                <img src={prediction4Icon} width="50px" alt="" /> Very Poor
            </div>)
        }
    }

    function navigateDetails() {
        if (serialNumber) {
            return `#home/details/${serialNumber}`
        }
        return "#home/details/"
    }

    function deviceSwitch(e) {
        switchDevice(data.machSerial, e.target.checked).then((resp)=> {
            setTimeout(()=>{
                if (serialNumber) {
                    getSerialData(serialNumber).then((resp)=> {
                        setData(resp);
                    })
                } else {
                    getData().then((resp)=> {
                        setData(resp);
                    })
                }
            }, 2000);
        })
    }
    
    return (
        <div class="page-content">
                <section class="grid-1">
                    <div class="item-1 col-lg-6 col-md-6 col-sm-6 col-xs-6 counter-matrix card bg-sky-100">
                            <div class="card-body">
                                <p class="text">Device Status</p>
                                <div class="counter-value">
                                    {get(data, 'isActive', false) ?
                                        <div class="indicator green"></div> :
                                        <div class="indicator red"></div>
                                    }
                                </div>
                            </div>
                    </div>
                    <div class="item-2 col-lg-6 col-md-6 col-sm-6 col-xs-6 counter-matrix card bg-purple-100">
                            <div class="card-body">
                                <p class="text">Switch-Immobilize</p>
                                <div style={{height: '50px'}}>
                                    <Switch size="large"
                                        color="success"
                                        onChange={deviceSwitch}
                                        checked={get(data, 'data.status', false)} />
                                </div>
                            </div>
                    </div>
                    <div class="item-2 col-lg-6 col-md-6 col-sm-6 col-xs-6 counter-matrix card bg-orange-100">
                            <div class="card-body">
                                <p class="text">Motor Health</p>
                                {machineHealth()}
                            </div>
                    </div>
                    {/* <div class="item-2 col-lg-3 col-md-3 col-sm-6 col-xs-6 counter-matrix card bg-green-100">
                        <a href="">
                            <div class="card-body">
                                <div class="counter-value">
                                    <img src={gaugeChart} width="100%" alt="" />
                                </div>

                            </div>
                        </a>
                    </div> */}
                    <div class="item-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 detail-matrix card bg-sky-100">
                        <div class="card-header">
                            Device Feed
                        </div>
                        <div class="card-body">
                            <div class="my-table">
                                <table class="table table-striped">
                                    <tr>
                                        <td><font class="fa fa-key"></font></td>
                                        <td>Total Key Cut:</td>
                                        <td>{get(data, 'data.keycount', '-')} nos</td>
                                    </tr>
                                    <tr>
                                        <td><font class="fa fa-bolt"></font></td>
                                        <td>Energy:</td>
                                        <td>{parseInt(get(data, 'data.energy', 0))/1000} units</td>
                                    </tr>
                                    <tr>
                                        <td><font class="fa fa-clock"></font></td>
                                        <td>Total Motor Run:</td>
                                        <td>{get(data, 'data.runtime', '-')} min</td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="item-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 detail-matrix card bg-purple-100">
                        <div class="card-header">Information</div>
                        <div class="card-body">
                            <div class="my-table">
                                <table class="table table-striped">
                                    <tr>
                                        <td><font class="fa fa-address-card"></font> </td>
                                        <td>Machine Name:</td>
                                        <td>DUO IQ</td>
                                    </tr>
                                    <tr>
                                        <td><font class="fa fa-user"></font> </td>
                                        <td>Customer Name:</td>
                                        <td>{`${get(data, 'owner.fName', '')} ${get(data, 'owner.lName', '')}`}</td>
                                    </tr>
                                    <tr>
                                        <td><font class="fa fa-location-dot"></font> </td>
                                        <td>Customer City:</td>
                                        <td>{get(data, 'owner.city', '')}</td>
                                    </tr>
                                    <tr>
                                        <td><font class="fa fa-asterisk"></font></td>
                                        <td>Serial Number:</td>
                                        <td>{get(data, 'machSerial', '-')}</td>
                                    </tr>
                                    <tr>
                                        <td><font class="fa fa-user"></font></td>
                                        <td>Dealer:</td>
                                        <td>{get(data, 'dealer.name', '-')}</td>
                                    </tr>
                                    <tr>
                                        <td><font class="fa fa-circle-info"></font></td>
                                        <td>Version:</td>
                                        <td>{get(data, 'data.ver', '-')}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="item-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 detail-matrix card bg-sky-100">
                        <div class="card-header">
                            Disclaimer
                        </div>
                        <div class="card-body">
                            <div class="my-table">
                                <table class="table table-striped">
                                    <tr>
                                        <td><font class="fa fa-exclamation-circle"></font></td>
                                        <td>Device operation active for 15 hours without Wi-Fi connection</td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="item-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-20">
                        <a href={navigateDetails()} class="btn btn-primary">
                            View Device Data</a>
                    </div>




                </section>
            </div>
    );
}

export default DeviceData;
